import { Fragment, useEffect, useState } from 'react';
import TestItemResult from './TestItemResult';
import TestItemForm from './TestItemForm';
import { checkLateralityIsDisabled } from 'utils/tests';
import { useTestDataContext } from 'contexts/TestDataContext';
import RefactoredListItem from 'components/list/list-item/RefactoredListItem';
import Icon from 'components/icon/Icon';
import RefactoredListItemStatus from 'components/list/list-item/RefactoredListItemStatus';
import RefactoredListItemLabelWrapper from 'components/list/list-item/RefactoredListItemLabelWrapper';
import RefactoredListItemLabel from 'components/list/list-item/RefactoredListItemLabel';
import RefactoredSubList from 'components/list/RefactoredSubList';
import RefactoredListBody from 'components/list/RefactoredListBody';

function TestItemListItem({
  entityId,
  testDataId,
  test,
  testItems,
  sides,
  version,
  edit,
  index,
  indexListItem,
  grouped,
  onClick
}) {
  const { testData } = useTestDataContext();
  const [hasResult, setHasResults] = useState(false);

  useEffect(() => {
    for (const testItemResult of testItems) {
      if (testData.formData?.[testItemResult.id]) {
        const results = Object.values(
          testData.formData[testItemResult.id]
        ).some(a => a.some(v => v !== '' && v !== null));
        setHasResults(results);
        if (results) break;
      } else {
        setHasResults(false);
      }
    }
  }, [testItems, testData.formData]);

  if (!grouped && test.testItems.length === 0) return '';

  return (
    <RefactoredListItem
      readOnly={!edit}
      disabled={test.isDependency}
      withSubList={grouped}
      done={hasResult}
      onClick={onClick}
      style={{ zIndex: indexListItem }}
      extraClassNames={'c-list__test-item'}
    >
      <RefactoredListItemStatus statusIcon={getStatusIcon(hasResult, edit)} />
      {grouped ? (
        // (If) List with subList === grouped
        <>
          <RefactoredListItemLabelWrapper>
            <RefactoredListItemLabel>{test.name}</RefactoredListItemLabel>
          </RefactoredListItemLabelWrapper>

          <RefactoredSubList>
            <RefactoredListBody>
              {testItems.map(testItem => (
                <RefactoredListItem key={testItem.id}>
                  <RefactoredListItemLabelWrapper>
                    <RefactoredListItemLabel>
                      {testItem.title}
                    </RefactoredListItemLabel>

                    <TestItemFormOrResult
                      testItem={testItem}
                      edit={edit}
                      entityId={entityId}
                      test={test}
                      testDataId={testDataId}
                      version={version}
                      sides={sides}
                      labelString={testItem.title}
                    />
                  </RefactoredListItemLabelWrapper>
                </RefactoredListItem>
              ))}
            </RefactoredListBody>
          </RefactoredSubList>
        </>
      ) : (
        // Not grouped (without sublist) === all in one list item

        <RefactoredListItemLabelWrapper>
          <RefactoredListItemLabel>{test.name}</RefactoredListItemLabel>
          {testItems.map(testItem => (
            <Fragment key={testItem.id}>
              <TestItemFormOrResult
                key={testItem.id}
                testItem={testItem}
                edit={edit}
                entityId={entityId}
                test={test}
                testDataId={testDataId}
                version={version}
                sides={sides}
                labelString={testItem.title}
              />
            </Fragment>
          ))}
        </RefactoredListItemLabelWrapper>
      )}
    </RefactoredListItem>
  );
}

const TestItemFormOrResult = props => {
  return (
    <>
      {props.edit ? (
        <TestItemForm
          testId={props.test.id}
          testDataId={props.testDataId}
          edit={props.edit}
          testItem={props.testItem}
          sidesDisabled={checkLateralityIsDisabled(props.test, props.sides)}
          refactoring
          labelString={props.labelString}
        />
      ) : (
        <TestItemResult
          testItem={props.testItem}
          sidesDisabled={checkLateralityIsDisabled(props.test, props.sides)}
          refactoring
        />
      )}
    </>
  );
};

const getStatusIcon = (hasResult, edit) => {
  let icon;
  if (hasResult) {
    icon = 'yes-white';
  } else if (!hasResult && edit) {
    icon = 'exercise';
  } else {
    icon = 'no-white';
  }

  return (
    <Icon
      id={icon}
      fillColor={'color-neutral-x-light'}
      strokeColor={''}
      mediumIcon={icon === 'exercise'}
    />
  );
};

export default TestItemListItem;
