import { useLazyQuery } from '@apollo/client';
import Template from 'models/Template';
import { QUERY_GET_TEMPLATES } from 'services/aws/templates-query';
import { getFilteredEntities } from 'utils/search';
import { useStore } from 'stores/Store';

const useTrainings = (entityId, user) => {
  const trainingsRef = useStore(state => state.trainingsRef);
  const setTrainings = useStore(state => state.setTrainings);
  const setTrainingsRef = useStore(state => state.setTrainingsRef);
  const setTrainingsFilter = useStore(state => state.setTrainingsFilter);

  const [getTrainings, { loading, error }] = useLazyQuery(QUERY_GET_TEMPLATES);

  const fetchTrainings = async options => {
    const { data } = await getTrainings({
      variables: { entityId },
      ...options
    });
    if (data?.getExerciseProgramTemplates) {
      const trainings = data.getExerciseProgramTemplates.map(
        template => new Template({ ...template })
      );
      const filteredTrainings = filterTrainings(
        { search: '', tags: [] },
        trainings
      );
      setTrainingsRef(filteredTrainings);

      return filteredTrainings;
    }
  };

  const filterTrainings = (filter, trainings) => {
    const trainingFilter = { ...filter } || {};
    if (user && user.isAthlete()) {
      trainingFilter.personId = user.id;
    }

    setTrainingsFilter(trainingFilter);

    const filteredTrainings = getFilteredEntities(
      trainings ?? trainingsRef ?? [],
      trainingFilter?.search || '',
      trainingFilter.tags || [],
      trainingFilter?.personId || null
    );

    setTrainings(filteredTrainings);
    return filteredTrainings;
  };

  return {
    loading,
    error,

    fetchTrainings,
    filterTrainings
  };
};

export default useTrainings;
