import { useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { SessionSporterListItem } from 'containers/partials/list-items/SessionSporterListItem';
import { ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION } from 'routes/RouteList';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Loader from 'components/loader/Loader';
import usePersonSessions from 'hooks/queries/usePersonSessions';

const PersonSessions = () => {
  const { push } = useHistory();
  const { entityId, groupId, sporterId, sessionId } = useParams();
  const { getPersonSessions, sessions, loading } = usePersonSessions(
    entityId,
    sporterId
  );

  useEffect(() => {
    if (sporterId) {
      getPersonSessions();
    }
  }, [sporterId]);

  return (
    <>
      {!sessions && loading && <Loader />}
      <List>
        <ListBody>
          {sessions &&
            sessions?.length > 0 &&
            sessions.map(session => (
              <SessionSporterListItem
                noMaxHeight
                key={session.id}
                session={session}
                active={sessionId === session.id}
                onClick={() =>
                  push(
                    generatePath(
                      ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION,
                      {
                        entityId,
                        groupId: groupId,
                        sessionId: session.id,
                        sporterId
                      }
                    )
                  )
                }
                percentage={session.getPersonTestPercentage(sporterId)}
              />
            ))}
        </ListBody>
      </List>
      {sessions && sessions?.length === 0 && (
        <Card centered>
          <CardBody empty>
            <Message iconFillColor="color-neutral-dark" empty icon="browse">
              <MessageText>
                <FormattedMessage {...messages.athleteSessionsEmpty} />
              </MessageText>
            </Message>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default PersonSessions;
