import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/modal/Modal';
import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { ButtonsGroup, Button } from 'components/button';
import { CardBody, CardFooter } from 'components/card';
import ReportOptions from 'containers/partials/forms/ReportOptions';
import { useSessionContext } from 'contexts/SessionContext';
import { uid } from 'react-uid';

const DownloadReportOptionsModal = ({ callback, onClose }) => {
  const intl = useIntl();
  const { options } = useSessionContext();

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card largeBody isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          {intl.formatMessage(messages.modalDownloadReportOptionsTitle)}
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          {intl.formatMessage(messages.modalDownloadReportOptionsSubtitle)}
        </CardHeaderSubtitle>
      </CardHeader>

      <CardBody modal style={{ overflowY: 'scroll' }}>
        <ReportOptions
          key={uid('modalReportOptions')}
          inlineSelect
          openAccordion
          onModal
        />
      </CardBody>
      <CardFooter modal style={{ zIndex: 9 }}>
        <ButtonsGroup style={{ width: '100%', justifyContent: 'center' }}>
          <Button secondary onClick={onCloseHandler}>
            <FormattedMessage {...messages.buttonCancel} />
          </Button>
          <Button
            primary
            onClick={async () => {
              onCloseHandler();
              await callback.method(callback.query, options);
            }}
          >
            <FormattedMessage
              {...messages[
                callback.query.includes('mail')
                  ? 'menuItemSessionMailAllReports'
                  : 'menuItemSessionDownloadAllReports'
              ]}
            />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

export default DownloadReportOptionsModal;
