import React, { useEffect, useState, useContext } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import messages from 'messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, AccordionContent } from 'components/accordion';
import AccordionButtonHooks from 'components/accordion/AccordionButtonHooks';
import {
  CardHeader,
  CardHeaderButtons,
  CardHeaderTitle
} from 'components/card/card-header';
import Card from 'components/card/Card';
import { useMutation } from '@apollo/client';
import {
  MUTATION_ADD_NOTE,
  MUTATION_EDIT_NOTE
} from 'services/aws/notes-query';
import { NoteTypes } from 'constants.js';
import { useNotificationQueue } from 'components/notification';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { StoreContext } from 'index';
import { useTestDataContext } from 'contexts/TestDataContext';
import DOMPurify from 'dompurify';
import TinyEditor from 'containers/partials/forms/TinyEditor';

const ReportRemarkInput = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const validationSchema = Yup.object().shape({
    note: Yup.string()
  });
  const { testData, refetchTestData } = useTestDataContext();

  const [edit, setEdit] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [addNote] = useMutation(MUTATION_ADD_NOTE);
  const [editNote] = useMutation(MUTATION_EDIT_NOTE);
  const [testDataNote, setTestDataNote] = useState({ note: '' });

  useEffect(() => {
    if (testData?.note) {
      setTestDataNote({
        note: testData?.note?.note ? testData?.note?.note : ''
      });
    } else {
      setTestDataNote({
        note: ''
      });
    }
    setOpenAccordion(testData?.note?.note?.length > 0);
  }, [testData]);

  const onSubmitHandler = async data => {
    const note = {
      linkId: testData.id,
      note: data.note,
      noteType: NoteTypes.TESTDATA
    };

    if (!testDataNote.note) {
      await addNote({ variables: { ...note } }).then(res => {
        notification.add(res.data.addNote.id, {
          message: intl.formatMessage(messages.noteSaved)
        });
        refetchTestData();
        setEdit(false);
      });
    } else {
      await editNote({
        variables: { ...note, id: testData.note.id }
      }).then(res => {
        notification.add(res.data.editNote.id, {
          message: intl.formatMessage(messages.noteSaved)
        });
        refetchTestData();
        setEdit(false);
      });
    }
  };

  const editAndOpenAccordion = () => {
    if (openAccordion) {
      setEdit(!edit);
    } else {
      setEdit(!edit);
      setOpenAccordion(true);
    }
  };

  if (user.hasAdminRole()) {
    return (
      <Card secondary>
        <Accordion>
          {() => (
            <>
              <AccordionButtonHooks
                id="trigger-1"
                name="accordion-story"
                checkbox
                isControlled
                checked={openAccordion}
                onChange={() => setOpenAccordion(!openAccordion)}
              >
                <CardHeader secondary>
                  <CardHeaderTitle>
                    <FormattedMessage
                      {...messages.sessionReportAthleteNoteFormTitle}
                    />
                  </CardHeaderTitle>
                  <CardHeaderButtons>
                    {!edit ? (
                      <Button tiny onClick={() => editAndOpenAccordion()}>
                        <Icon id="edit" strokeColor="color-neutral-dark" />
                      </Button>
                    ) : (
                      <Button tiny onClick={() => editAndOpenAccordion()}>
                        <Icon id="close" strokeColor="color-primary-dark" />
                      </Button>
                    )}
                  </CardHeaderButtons>
                </CardHeader>
              </AccordionButtonHooks>

              {((testDataNote?.note && testDataNote?.note.length > 0) ||
                edit) && (
                <AccordionContent>
                  {edit ? (
                    <Formik
                      initialValues={testDataNote}
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                      validateOnChange={false}
                      validateOnBlur={false}
                      onSubmit={onSubmitHandler}
                    >
                      {({ submitForm, resetForm, isSubmitting }) => (
                        <Form>
                          <TinyEditor note={testDataNote.note} />
                          <FormEditFooterButtons
                            edit={edit}
                            setEdit={setEdit}
                            onCancel={resetForm}
                            onSubmit={submitForm}
                            disabledSubmit={isSubmitting}
                            topMargin={12}
                          />
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <div
                      className="c-input--textarea-readonly"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(testDataNote.note, {
                          ALLOWED_TAGS: [
                            'p',
                            'b',
                            'strong',
                            'i',
                            'em',
                            'br',
                            'ul',
                            'li',
                            'ol',
                            'pre',
                            'h1',
                            'h2',
                            'h3',
                            'h4',
                            'h5',
                            'h6',
                            'address'
                          ]
                        })
                      }}
                    />
                  )}
                </AccordionContent>
              )}
            </>
          )}
        </Accordion>
      </Card>
    );
  } else if (testDataNote?.note) {
    return (
      <Card secondary>
        <Accordion>
          {() => (
            <>
              <AccordionButtonHooks
                id="trigger-1"
                name="accordion-story"
                checkbox
                isControlled
                checked={openAccordion}
                onChange={() => setOpenAccordion(!openAccordion)}
              >
                <CardHeader secondary>
                  <CardHeaderTitle>
                    <FormattedMessage
                      {...messages.sessionReportAthleteNoteFormTitle}
                    />
                  </CardHeaderTitle>
                </CardHeader>
              </AccordionButtonHooks>
              <AccordionContent>
                <div
                  className="c-input--textarea-readonly"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(testDataNote.note, {
                      ALLOWED_TAGS: ['p', 'b', 'i', 'br', 'ul', 'li', 'ol']
                    })
                  }}
                />
              </AccordionContent>
            </>
          )}
        </Accordion>
      </Card>
    );
  } else {
    return <></>;
  }
};

export default ReportRemarkInput;
