import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_SESSIONS_OF_SPORTER } from 'services/aws/session-query';
import { parseTestSessionDataToModel } from 'models/Entity';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import Session from 'models/Session';

const usePersonSessions = (entityId, personId) => {
  const [sessions, setSessions] = useState(null);

  const [fetchPersonSession, { loading }] = useLazyQuery(
    QUERY_GET_SESSIONS_OF_SPORTER,
    {
      variables: { personId: personId, entityId },
      skip: !personId
    }
  );

  const getPersonSessions = async () => {
    const { data } = await fetchPersonSession();

    if (data?.getTestSessionsOfPerson) {
      const sessions = parseTestSessionDataToModel(
        data.getTestSessionsOfPerson
      );
      setSessions(
        sort(
          sessions.map(s => {
            return new Session({
              ...s,
              finished: s.getPersonTestPercentage(personId)
            });
          }),
          {
            keys: [
              {
                key: 'startdate',
                dataType: SORT_DATA_TYPES.NUMBER,
                desc: true
              }
            ]
          }
        )
      );
    }
  };

  return { getPersonSessions, sessions, loading };
};

export default usePersonSessions;
