import { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ErrorMessage, getIn } from 'formik';
import InputErrorMessage from './InputErrorMessage';
import { Label, Slider, SliderThumb, SliderTrack } from 'react-aria-components';

import { Button } from 'components/button';
import { isNumber } from 'utils/math';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';

const FieldRange = props => {
  const {
    id,
    inline,
    errorMessage,
    errors,
    disabled,
    max,
    maxLabel,
    min,
    minLabel,
    name,
    readOnly,
    step,
    style,
    value,
    onChange,
    onChangeEnd,
    label,
    ariaLabel,
    ariaLabelledby,
    ariaDescribedby
  } = props;

  const [sliderValue, setSliderValue] = useState((max - min) / 2);

  useEffect(() => {
    if (value !== sliderValue) {
      setSliderValue(isNumber(value) ? value : (max - min) / 2);
    }
  }, [value]);

  return (
    <div
      className={classNames('c-input-slider', {
        'c-input--read-only': readOnly,
        'c-input--disabled': disabled,
        'c-input--inline': inline
      })}
      style={style}
    >
      <div className={classNames('c-input-slider__wrapper')}>
        {minLabel ? (
          <span className="c-input__label c-input--range-extreme-values">
            {minLabel}
          </span>
        ) : (
          <span className="c-input__label c-input--range-extreme-values">
            {min}
          </span>
        )}

        <Slider
          id={id}
          className={values =>
            classNames(values.defaultClassName, {
              'c-input--slider-unset': value.length === 0
            })
          }
          value={sliderValue}
          minValue={min}
          maxValue={max}
          step={step}
          isDisabled={disabled}
          onChange={onChange}
          onChangeEnd={onChangeEnd}
          aria-label={(!label || label?.length <= 0) && ariaLabel}
          aria-labelledby={
            (!label || label?.length <= 0) &&
            (!ariaLabel || ariaLabel.length <= 0) &&
            ariaLabelledby
          }
          aria-describedby={ariaDescribedby}
        >
          {label?.length > 0 && (
            <Label className="u-hidden-visually">{label}</Label>
          )}
          {!disabled && (
            <Button
              tiny
              ghost
              onClick={e => {
                onChangeEnd('');
              }}
            >
              <FormattedMessage {...messages.modalConfirmResetButton} />
            </Button>
          )}
          <SliderTrack>
            <SliderThumb name={name} />
          </SliderTrack>
        </Slider>

        {maxLabel ? (
          <span className="c-input__label c-input--range-extreme-values">
            {maxLabel}
          </span>
        ) : (
          <span className="c-input__label c-input--range-extreme-values">
            {max}
          </span>
        )}
      </div>

      {/* TODO: check styles/ en errors handling */}
      {errorMessage && <InputErrorMessage>{errorMessage}</InputErrorMessage>}

      {errors && errors[name] && typeof errors[name] === 'string' && (
        <ErrorMessage name={name} component={InputErrorMessage} />
      )}

      {errors && typeof errors[name] !== 'string' && getIn(errors, name) && (
        <InputErrorMessage>{getIn(errors, name)}</InputErrorMessage>
      )}
    </div>
  );
};

const inputPropTypes = {
  id: PropTypes.string,

  // CSS Classes added on wrapper
  extraClassNames: PropTypes.string, // also added in input ???
  inline: PropTypes.bool,
  // readOnly
  // disabled

  errorMessage: PropTypes.string,
  label: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  errors: PropTypes.any,

  type: PropTypes.string, // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types

  disabled: PropTypes.bool,
  max: PropTypes.number, // for numeric typesL: Max Value
  min: PropTypes.number, // for numeric types: Min Value
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  name: PropTypes.string,
  pattern: PropTypes.string, // for password, text, tel
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool, // for text, search, url, tel, email, date, month, week, time, datetime-local, number, and password input types
  step: PropTypes.number, // for numeric types
  title: PropTypes.string,
  value: PropTypes.any,

  style: PropTypes.object,

  onChange: PropTypes.func,
  onChangeEnd: PropTypes.func
};

FieldRange.propTypes = inputPropTypes;

export default FieldRange;
