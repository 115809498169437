import { PanelSubtitle } from 'components/panel';
import messages from 'messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { getLocalDateFormat } from 'utils/date';
import DOMPurify from 'dompurify';

const getSharedWithAsHtml = (shares, user, intl) => {
  let html = `. ${intl.formatMessage(messages.videoSharedWithLabel)} `;

  let personsAsString = shares
    .reduce((prev, curr) => {
      if (user.id === curr.sharedWith.id) {
        const meStr = `<b>${intl.formatMessage(messages.meLabel)}</b>`;
        if (!prev.includes(meStr)) prev.unshift(meStr);
        return prev;
      }

      const nameStr = `<b>${curr.sharedWith.firstname} ${curr.sharedWith.lastname}</b>`;
      if (!prev.includes(nameStr)) prev.push(nameStr);
      return prev;
    }, [])
    .join(', ')
    .replace(/, ([^,]*)$/, ` ${intl.formatMessage(messages.andLabel)} $1`);

  html += personsAsString;

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(html)
      }}
    ></span>
  );
};

const PanelSubtitleVideo = ({ video, user }) => {
  const intl = useIntl();

  return (
    <PanelSubtitle>
      {video.isMine(user) ? (
        <>
          <FormattedMessage
            {...messages.annotationDateCreatedLabel}
            values={{
              date_created: getLocalDateFormat(video.date_created)
            }}
          />
        </>
      ) : (
        <>
          <FormattedMessage
            {...messages.annotationDateCreatedAndOwnerLabel}
            values={{
              date_created: getLocalDateFormat(video.date_created),
              person: `${video.person?.firstname} ${video.person?.lastname}`,
              b: chunks => <b>{chunks}</b>
            }}
          />
        </>
      )}
      {video?.shares?.length > 0 &&
        getSharedWithAsHtml(video.shares, user, intl)}
    </PanelSubtitle>
  );
};

export default PanelSubtitleVideo;
