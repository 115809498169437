import { useContext, useRef } from 'react';
import { FieldArray, Form, Formik, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Tooltip from 'components/tooltip/Tooltip';
import { renderUnit } from 'utils/results';
import InputWrapper from 'components/input/InputWrapper';
import FieldInput from 'components/input/FieldInput';
import { Lateralities, Laterality } from 'constants.js';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { StoreContext } from 'index';
import FieldRange from 'components/input/FieldRange';
import { useTestDataContext } from 'contexts/TestDataContext';
import FieldSelect from 'components/input/FieldSelect';
import DOMPurify from 'dompurify';

function TestItemForm({
  testId,
  testDataId,
  testItem,
  sidesDisabled,
  edit,
  small = false,
  refactoring = false,
  labelString
}) {
  const intl = useIntl();
  const {
    uiState: { locale }
  } = useContext(StoreContext);
  const rootRef = useRef();
  // const { focused, bind } = useFormLeave();
  const { formData, updateFormData, submitData, setFormIsDirty } =
    useTestDataContext();

  const onChangeHandler = data => {
    setFormIsDirty(true);
    updateFormData(testId, testItem, testDataId, data);
  };

  const onSubmitHandler = () => {
    submitData(testDataId);
  };

  const validationSchema = key =>
    Yup.object().shape({
      [key]: Yup.object().shape({
        [Laterality.LEFT]: Yup.array().of(
          Yup.number()
            .min(
              testItem.minimum,
              intl.formatMessage(messages.minimumValidation, {
                value: testItem.minimum
              })
            )
            .max(
              testItem.maximum,
              intl.formatMessage(messages.maximumValidation, {
                value: testItem.maximum
              })
            )
            .nullable()
            .typeError(intl.formatMessage(messages.numberValidation))
        ),
        [Laterality.RIGHT]: Yup.array().of(
          Yup.number()
            .min(
              testItem.minimum,
              intl.formatMessage(messages.minimumValidation, {
                value: testItem.minimum
              })
            )
            .max(
              testItem.maximum,
              intl.formatMessage(messages.maximumValidation, {
                value: testItem.maximum
              })
            )
            .nullable()
            .typeError(intl.formatMessage(messages.numberValidation))
        ),
        [Laterality.UNILATERAL]: Yup.array().of(
          Yup.number()
            .min(
              testItem.minimum,
              intl.formatMessage(messages.minimumValidation, {
                value: testItem.minimum
              })
            )
            .max(
              testItem.maximum,
              intl.formatMessage(messages.maximumValidation, {
                value: testItem.maximum
              })
            )
            .nullable()
            .typeError(intl.formatMessage(messages.numberValidation))
        )
      })
    });

  if (!testItem) return '';

  return (
    <Formik
      initialValues={formData}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={validationSchema(testItem.id)}
      onSubmit={onSubmitHandler}
    >
      {({ values, errors, touched, handleBlur }) => {
        let minLabel;
        let maxLabel;
        let inputWithOptionsHint;

        if (!values[testItem.id]) return null;

        if (testItem.input_type === 'slider') {
          minLabel = testItem.input_type_parameters.minLabel;
          maxLabel = testItem.input_type_parameters.maxLabel;
        } else if (testItem.input_type === 'select_with_options') {
          inputWithOptionsHint = intl.formatMessage(
            messages.selectOptionsHints
          );
        } else {
          minLabel = testItem.minimum;
          maxLabel = testItem.maximum;
        }

        return (
          // If the FieldInputs are two dropdowns
          // this form should have ALSO the class:
          // `.c-list__item-small-editing-area-two-dropdowns`
          // See: Level 2 Rotatory Stability @ Storybook > http://localhost:6006/?path=/story/listitem--testitem-with-multiple-inputs
          // {...bind}
          <Form
            className={classNames('', {
              'c-list__item-small-editing-area': small,
              'c-list__form-with-slider':
                !refactoring && testItem.input_type === 'slider',

              // OLD: not refactoring
              'c-list__item-editing-area': !small && !refactoring,
              'c-list__form-with-select':
                !refactoring && testItem.input_type === 'select_with_options',

              // NEW: refactoring
              'r-list__form': refactoring,
              'r-list__form-select':
                refactoring && testItem.input_type === 'select_with_options',
              'r-list__form-with-2-elements':
                values[testItem.id][0] !== undefined ||
                values[testItem.id][1] !== undefined,
              'r-list__form-with-slider':
                refactoring && testItem.input_type === 'slider',
              'r-list__form-long':
                refactoring &&
                (testItem.input_type === 'select_with_options' ||
                  testItem.input_type === 'slider')
            })}
            ref={rootRef}
          >
            {values && edit && (
              <Tooltip
                id="info"
                left
                icon="alert"
                iconStrokeColor="color-neutral-dark"
                tabIndex={-1}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      inputWithOptionsHint
                        ? inputWithOptionsHint
                        : intl.formatMessage(messages.betweenValidation, {
                            minimum: `<b style="opacity: .75;">${minLabel}</b>`,
                            maximum: `<b style="opacity: .75;">${maxLabel}</b>`
                          })
                    )
                  }}
                />
              </Tooltip>
            )}
            {values &&
              Object.keys(values[testItem.id]).map(laterality => {
                const hasTrialsLabel =
                  values[testItem.id][laterality].length > 1;

                return (
                  <FieldArray
                    key={`${testItem.id}-${laterality}`}
                    name={`${testItem.id}`}
                    render={({ form }) => (
                      <>
                        {values[testItem.id][laterality].map((value, trial) => {
                          const renderedUnit = renderUnit(testItem.unit);
                          let labelWithTrials =
                            hasTrialsLabel && renderedUnit.length > 0 ? (
                              <div className="c-list__item-trial">
                                <FormattedMessage
                                  {...messages.labelTrial}
                                  values={{
                                    trial: trial + 1
                                  }}
                                />

                                <span className="c-input__group-label u-margin-left-tiny">
                                  {renderedUnit}
                                </span>
                              </div>
                            ) : hasTrialsLabel ? (
                              <div className="c-list__item-trial ">
                                <FormattedMessage
                                  {...messages.labelTrial}
                                  values={{
                                    trial: trial + 1
                                  }}
                                />
                                <span className="c-input__group-label" />
                              </div>
                            ) : (
                              ``
                            );

                          return (
                            // For the curious: if laterality is 0, will surely have
                            // a 1, because 0 is left, en 1 is right. So we will
                            // render both (left and right) only once, when we reach
                            // 1 === right.
                            // 2 == no laterality, and will only have one input.
                            (parseInt(laterality) === Laterality.RIGHT ||
                              parseInt(laterality) ===
                                Laterality.UNILATERAL) && (
                              <InputWrapper
                                key={`${testItem.id}-${laterality}-${trial}`}
                                trialLabel={labelWithTrials}
                                label={renderUnit(testItem.unit)}
                                //  TODO REMOVE: REFACTORING
                                refactoring={refactoring}
                              >
                                {parseInt(laterality) === Laterality.RIGHT &&
                                  Array.from(Array(2).keys()).map(
                                    laterality => (
                                      <InputField
                                        key={`${testItem.id}${laterality}${trial}`}
                                        id={testItem.id}
                                        locale={locale}
                                        testItem={testItem}
                                        inputType={testItem.input_type}
                                        laterality={laterality}
                                        trial={trial}
                                        outfaded={sidesDisabled[laterality]}
                                        form={form}
                                        errors={errors}
                                        touched={touched}
                                        handleChange={onChangeHandler}
                                        handleBlur={handleBlur}
                                        labelString={labelString}
                                      />
                                    )
                                  )}

                                {/* This is the comp when there are no lateralities */}
                                {parseInt(laterality) ===
                                  Laterality.UNILATERAL && (
                                  <InputField
                                    key={`${testItem.id}${laterality}${trial}`}
                                    id={testItem.id}
                                    locale={locale}
                                    testItem={testItem}
                                    inputType={testItem.input_type}
                                    laterality={laterality}
                                    trial={trial}
                                    form={form}
                                    errors={errors}
                                    touched={touched}
                                    handleChange={onChangeHandler}
                                    handleBlur={handleBlur}
                                    labelString={labelString}
                                  />
                                )}
                              </InputWrapper>
                            )
                          );
                        })}
                      </>
                    )}
                  />
                  // End FieldArray
                );
              })}
            {/*<AutoSaveHandler setUpdatedData={setUpdatedData} />*/}
          </Form>
        );
      }}
    </Formik>
  );
}

export default TestItemForm;

/*const AutoSaveHandler = ({ setUpdatedData }) => {
  const { values, dirty, isValid } = useFormikContext();
  useEffect(() => {
    if (dirty) {
      setUpdatedData(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, dirty, isValid]);

  return null;
};*/

const getErrors = (errors, testItemId, laterality, trial) => {
  return (
    typeof getIn(errors, `${testItemId}.${laterality}.${trial}`) === 'string'
  );
};

const InputField = ({
  id,
  testItem,
  inputType,
  laterality,
  trial,
  disabled,
  outfaded,
  form,
  errors,
  touched,
  handleBlur,
  handleChange,
  ariaDescribedby,
  labelString
}) => {
  const {
    uiState: { locale }
  } = useContext(StoreContext);

  const { values, initialValues } = useFormikContext();
  const label = Number(laterality) !== 2 ? Lateralities[laterality] : '';

  switch (inputType) {
    case 'select_with_options':
      // get the correct language
      const options = testItem.input_type_parameters.options.map(option => {
        let label = option.label;
        if (option.labels?.[locale]) {
          label = option.labels?.[locale];
        }
        return { ...option, label };
      });
      return (
        <FieldSelect
          id={`id-${id}-${Lateralities[laterality]}-${trial}`}
          name={`${testItem.id}.${laterality}.${trial}`}
          label={label}
          options={options}
          value={options.find(
            o => o.value === form.values[testItem.id]?.[laterality]?.[trial]
          )}
          outfaded={outfaded}
          maxMenuHeight={200}
          isSearchable={false}
          isClearable={true}
          onChange={async option => {
            form.setFieldValue(
              `${testItem.id}.${laterality}.${trial}`,
              option?.value ?? ''
            );
            if (handleChange) {
              //let fieldValues = form.values[testItem.id];

              handleChange({
                ...form.values,
                ...{
                  [testItem.id]: {
                    ...{
                      ...form.values[testItem.id],
                      ...{ [laterality]: [option?.value ?? ''] }
                    }
                  }
                }
              });
            }
          }}
          onBlur={e => {
            if (handleBlur) handleBlur(e);
          }}
        />
      );
    case 'slider':
      return (
        //   Value ?
        <FieldRange
          id={`id-${id}-${Lateralities[laterality]}-${trial}`}
          label={labelString}
          ariaDescribedby={ariaDescribedby}
          name={`${testItem.id}.${laterality}.${trial}`}
          step={1}
          disabled={outfaded || disabled}
          min={testItem.minimum}
          max={testItem.maximum}
          minLabel={
            testItem.input_type_parameters?.minLabels?.[locale] ??
            testItem.input_type_parameters.minLabel
          }
          maxLabel={
            testItem.input_type_parameters?.maxLabels?.[locale] ??
            testItem.input_type_parameters.maxLabel
          }
          value={form.values[testItem.id]?.[laterality]?.[trial]}
          onChange={value => {
            form.setFieldValue(
              `${testItem.id}.${laterality}.${trial}`,
              value ?? ''
            );
          }}
          onChangeEnd={async value => {
            form.setFieldValue(
              `${testItem.id}.${laterality}.${trial}`,
              value ?? ''
            );
            if (handleChange) {
              handleChange({
                ...form.values,
                ...{
                  [testItem.id]: {
                    ...{
                      ...form.values[testItem.id],
                      ...{ [laterality]: [value ?? ''] }
                    }
                  }
                }
              });
            }
          }}
        ></FieldRange>
      );
    case 'free_number':
    default:
      return (
        <FieldInput
          id={`id-${id}-${Lateralities[laterality]}-${trial}`}
          name={`${testItem.id}.${laterality}.${trial}`}
          type="text"
          outfaded={outfaded}
          // icon={form.touched?.[testItem.id]?.[laterality] && submitStatus}
          onChange={() => {
            if (
              handleChange &&
              JSON.stringify(values) !== JSON.stringify(initialValues)
            )
              handleChange(values);
          }}
          onBlur={e => {
            if (handleBlur) handleBlur(e);
          }}
          errors={errors}
          hasError={getErrors(errors, laterality, trial)}
          touched={touched}
        >
          {label}
        </FieldInput>
      );
  }
};
