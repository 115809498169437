import React, { useContext, useRef, useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  startTestSessionByClientId,
  MUTATION_UPDATE_TEST_DATA,
  QUERY_GET_SESSION_BY_ID,
  QUERY_GET_TESTDATA_BY_SESSION
} from 'services/aws/session-query';
import { PackageTypes } from 'constants.js';
import { MessageType, StorageType, FinishedState } from 'enums';
import './iframe.scss';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { getAppLocale } from 'utils/locale';
import { StoreContext } from 'index';
import { useSessionContext } from 'contexts/SessionContext';
import { useMutation } from '@apollo/client';
import { useUID } from 'react-uid';

const getIFrameURLForPackageId = (packageId, locale, session) => {
  const appLocale = getAppLocale(locale);
  if (packageId === PackageTypes.ILIKE) {
    const sessionLocale = session.language
      ? getAppLocale(session.language)
      : appLocale;
    return `${process.env.REACT_APP_ILIKE_DOMAIN}/${sessionLocale}/testers`;
  } else if (packageId === PackageTypes.IDO) {
    return `${process.env.REACT_APP_IDO_DOMAIN}/${appLocale}/test/selection`;
  } else if (packageId === 'talent') {
    return `${process.env.REACT_APP_IDO_DOMAIN}/${appLocale}/test/selection`;
  } else {
    return '';
  }
};

const getIFrameUrl = (packageId, session, sessionId, sporterId, locale) => {
  if (packageId === PackageTypes.SAP) {
    const sporter = session
      ? session.getSporterById(sporterId)
      : { uid: '', firstname: '' };
    return `${
      session?.config?.url
        ? session.config.url
            .replace('{sporterId}', sporterId)
            .replace('{sessionId}', sessionId)
            .replace('{FIRSTNAME}', encodeURIComponent(sporter.firstname))
            .replace('{LASTNAME}', encodeURIComponent(sporter.lastname))
            .replace('{UID}', sporter.uid || '')
            .replace('{LOCALE}', locale || 'en')
        : ''
    }`;
  }
  return getIFrameURLForPackageId(packageId, locale, session);
};

let retryCount = 0;
const startTestSession = async (personId, sessionId) => {
  const testSessionId = await startTestSessionByClientId(personId, sessionId);

  if (retryCount > 3) {
    Sentry.captureMessage('Could not start IDO test session');
    throw new Error('Could not start test session');
  }

  if (testSessionId === null) {
    retryCount++;
    // retry creation
    return await startTestSession(personId, sessionId);
  }

  retryCount = 0;
  return testSessionId;
};

const TestSession = ({ packageId, sessionId, sporterId, entityId }) => {
  const d = new Date();
  const uid = useUID();
  const {
    uiState: { locale, isLocaleReady }
  } = useContext(StoreContext);
  const { session, sessionTestData, fetchTestDataBySession, loading, error } =
    useSessionContext();
  const { push } = useHistory();
  const { search } = useLocation();
  const iframe = useRef();
  const [updateTestData] = useMutation(MUTATION_UPDATE_TEST_DATA);
  const [appReady, setAppReady] = useState(false);
  const [testAppUrl, setTestAppUrl] = useState(
    `${process.env.REACT_APP_IDO_DOMAIN}?v=${d.getTime()}`
  );

  useEffect(() => {
    window.addEventListener('message', receiveData, false);
    return () => {
      window.removeEventListener('message', receiveData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const customLocale = new URLSearchParams(search).get('locale');

    const fetchData = async () => {
      await fetchTestDataBySession(session);
      const url = getIFrameUrl(
        packageId,
        session,
        session.id,
        sporterId,
        customLocale || locale
      );
      setTestAppUrl(url);
    };
    if (packageId && session.id && locale) {
      fetchData();
    }
    if (session.isSAP) {
      setAppReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageId, session, sporterId, locale]);

  useEffect(() => {
    if (sessionTestData && iframe.current?.contentWindow && appReady) {
      if (session.finished !== FinishedState.FINISHED) {
        sendInitData();
      } else {
        push('/');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionTestData, iframe, appReady, loading]);

  const receiveData = async event => {
    if (
      event.origin !== process.env.REACT_APP_ILIKE_DOMAIN &&
      event.origin !== process.env.REACT_APP_IDO_DOMAIN
    )
      return;

    let message;
    try {
      message = JSON.parse(event.data);
    } catch (e) {
      //no valid data given via iframe message
    }

    switch (message?.type) {
      case MessageType.TEST_STARTED:
        const personId = message.data.sporter.id;
        const testSessionId = await startTestSession(personId, sessionId);

        sessionStorage.setItem(StorageType.ACTIVE_SESSION_ID, testSessionId);

        const messageSession = {
          type: MessageType.TEST_RECEIVED_SESSION_ID,
          data: {
            activeTestSessionId: testSessionId
          }
        };

        sendMessageToIframe(messageSession);
        break;
      case MessageType.TEST_FINISHED:
        const activeTestSessionId = sessionStorage.getItem(
          StorageType.ACTIVE_SESSION_ID
        );

        if (activeTestSessionId) {
          await updateTestData({
            variables: {
              id: activeTestSessionId,
              data: JSON.stringify(message.data.data),
              result: JSON.stringify(message.data.result),
              finished:
                message.data?.status === 'finished'
                  ? message.data?.status
                  : FinishedState.STARTED
            },
            refetchQueries: [
              {
                query: QUERY_GET_TESTDATA_BY_SESSION,
                variables: {
                  testSessionId: sessionId
                }
              },
              {
                query: QUERY_GET_SESSION_BY_ID,
                variables: { testSessionId: sessionId, entityId }
              }
            ]
          });
          sessionStorage.removeItem(StorageType.ACTIVE_SESSION_ID);
          //broadcastStore.postMessage();

          //const sessionData = await getSessionForTest(entityId, sessionId);
          // if (session.finished !== FinishedState.FINISHED) {
          sendInitData();
          // }
        }
        break;
      case MessageType.IFRAME_READY:
        setAppReady(true);
        break;
      default:
      //Ignore event
    }
  };

  const sendInitData = () => {
    const message = {
      type: MessageType.INIT_DATA,
      data: {
        sessionName: session.name,
        sporters: session.sporters,
        testData: sessionTestData,
        config: { version: session.version, tests: session.tests }
      }
    };

    if (session.testSets?.[0]?.title) {
      message.data.name = session.testSets?.[0]?.title;
    }
    sendMessageToIframe(message);
  };

  const sendMessageToIframe = message => {
    try {
      iframe.current.contentWindow.postMessage(JSON.stringify(message), '*');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (loading) return <></>;
  if (error)
    return (
      <p>
        <FormattedMessage
          {...messages.errorMessage}
          values={{
            message: error.message
          }}
        />
      </p>
    );

  return (
    isLocaleReady && (
      <iframe
        className={classNames(
          'c-iframe--content js-iframe-ilike',
          'c-iframe--show'
        )}
        ref={iframe}
        title={'package'}
        name={uid}
        width="100vw"
        height="100%"
        scrolling="yes"
        src={testAppUrl}
      />
    )
  );
};

export default TestSession;
