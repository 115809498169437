import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_TESTSETS_LIST } from 'services/aws/testsets-query';
import { TestSetV2Type } from 'constants.js';
import { useIntl } from 'react-intl';
import { ErrorMessage } from 'formik';
import { sort } from 'utils/sort';
import messages from 'messages';
import { groupBy } from 'utils/array';
import InputErrorMessage from 'components/input/InputErrorMessage';
import FieldSelect from 'components/input/FieldSelect';

function TestsetFieldSelect({
  name,
  entityId,
  onChange,
  testSets,
  value,
  readOnly = false,
  errors,
  hasLabel = true,
  label,
  isClearable
}) {
  const intl = useIntl();
  const [testSetOptions, setTestSets] = useState();
  const [selectedTestSet, setSelectedTestSet] = useState();
  const [getTestSets, { loading, error, data }] = useLazyQuery(
    QUERY_GET_TESTSETS_LIST,
    {
      variables: {
        entityId,
        types: [
          TestSetV2Type.TALENT,
          TestSetV2Type.REHAB,
          TestSetV2Type.PREVENTION
        ]
      },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (testSets) {
      setTestSets(testSets);
    }
  }, [testSets]);

  useEffect(() => {
    if (data?.getTestSetsV2) {
      const testSets = data.getTestSetsV2;
      const groupedTests = groupBy(testSets, ['originalId']);
      /*
      Object.keys(groupedTests).forEach(key => {
        if (key && groupedTests[key].length > 1) {
          const lastVersion = groupedTests[key].reduce((acc, test) =>
            acc.version > test.version ? acc : test
          );
          groupedTests[key] = [lastVersion];
        }
      });*/

      const filteredTestSets = Object.values(groupedTests).reduce(
        (acc, tests) => {
          return [...acc, ...tests];
        },
        []
      );
      setTestSets(sort(filteredTestSets, { keys: [{ key: 'title' }] }));
      setSelectedTestSet(testSets.find(testSet => testSet.id === value));
    }
  }, [data]);

  const onSelectHandler = select => {
    setSelectedTestSet(select);
    onChange(
      select ? testSets.find(testSet => testSet.id === select.value) : null
    );
  };

  if (error) {
    return intl.formatMessage(messages.errorMessage, {
      message: 'Error loading Testset types'
    });
  }

  const getPlaceHolder = () => {
    if (loading) {
      return intl.formatMessage(messages.globalLoading);
    } else {
      return intl.formatMessage(messages.testsetSelectPlaceholder);
    }
  };

  return (
    <>
      <FieldSelect
        id="testsetType"
        label={
          hasLabel
            ? intl.formatMessage(messages.labelTestSetType)
            : label.length > 0
              ? label
              : ''
        }
        name={name}
        onChange={select => onSelectHandler(select)}
        value={selectedTestSet}
        readOnly={readOnly}
        disabled={loading || readOnly}
        placeholder={getPlaceHolder()}
        options={testSetOptions?.map(testSet => ({
          key: testSet.id,
          value: testSet.id,
          label: testSet.title
        }))}
        isClearable={isClearable}
      />
      {errors && <ErrorMessage name={name} component={InputErrorMessage} />}
    </>
  );
}

export default TestsetFieldSelect;
