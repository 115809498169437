import React, { useContext, useEffect } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { generatePath } from 'react-router-dom';
import { FinishedState } from 'enums';

import { Panel } from '../../../components/panel';
import PanelHeader from '../../../components/panel/PanelHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import PanelTitleWrapper from '../../../components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from '../../../components/panel/panel-title/PanelTitle';
import PanelSubtitle from '../../../components/panel/panel-title/PanelSubtitle';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT
} from 'routes/RouteList';
import { getAgeFromDobOnSession } from 'utils/date';
import messages from 'messages';
import PanelBody from '../../../components/panel/PanelBody';
import PanelTitleButton from '../../../components/panel/panel-title/PanelTitleButton';
import { openNewWindow } from 'utils/browser';
import { MenuWrapper } from '../../../components/menu';
import RemoveSessionButton from '../../partials/buttons/RemoveSessionButton';
import RemoveSporterFromSessionButton from '../../partials/buttons/RemoveSporterFromSessionButton';
import { PanelTitleButtonsGroup } from '../../../components/panel/panel-title';
import { getTestSessionURL } from 'utils/url';
import { PackageTypes } from 'constants.js';
import { StoreContext } from 'index';
import { useSessionContext } from 'contexts/SessionContext';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import SessionTalentV1 from 'containers/pages/persons/SessionTalentV1';
import SessionTabs from 'containers/pages/persons/SessionTabs';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { useTestDataContext } from 'contexts/TestDataContext';
import Loader from 'components/loader/Loader';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import DownloadReportsButton from 'containers/partials/session-report-buttons/DownloadReportsButton';

const PersonSession = () => {
  const ability = useAbility(AbilityContext);
  const {
    authStore: {
      user: { rootEntityId }
    },
    routing: { locale }
  } = useContext(StoreContext);

  const { entityId, groupId, sporterId, sessionId, testDataId } = useParams();
  const { push, replace } = useHistory();
  let location = useLocation();
  const intl = useIntl();
  const person = useEntityContext(s => s.person);
  const { session, options, sessionLoading } = useSessionContext();
  const {
    testData,
    fetchTestData,
    activeDate,
    unsetAllData,
    setNewActiveDate,
    testDataLoading
  } = useTestDataContext();
  const edit = useRouteMatch(
    ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA_EDIT
  )?.isExact;

  const active = useRouteMatch([
    ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT_TESTDATA,
    ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT,
    ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA_EDIT,
    ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA,
    ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
    ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION
  ]);

  const isSessionRootView = useRouteMatch({
    path: ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION,
    strict: true,
    exact: true
  });
  const isTestsView = useRouteMatch({
    path: [
      ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
      ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA
    ],
    strict: true
  });
  const isReportView = useRouteMatch({
    path: ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT_TESTDATA,
    strict: true
  });

  useEffect(() => {
    if (session?.id !== sessionId) return;

    if (
      (!testDataId && session && (isReportView || isTestsView)) ||
      isSessionRootView
    ) {
      let testData;
      if (session.isRecurring) {
        if (!activeDate) {
          const today = new Date();
          setNewActiveDate(today);
          testData = session.getTestDataByDate(sporterId, today);
        } else {
          testData = session.getTestDataByDate(sporterId, activeDate);
        }
      } else {
        testData = session.getLastTestData(sporterId);
      }
      if (testData?.id) {
        const selectedPath =
          testData.finishedPercentage > 0 && !isTestsView
            ? ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT_TESTDATA
            : ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA;

        replace(
          generatePath(selectedPath, {
            entityId,
            groupId,
            sporterId,
            sessionId,
            testDataId: testData.id
          })
        );
      } else {
        if (isSessionRootView) {
          replace(
            generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS, {
              entityId,
              groupId,
              sporterId,
              sessionId
            })
          );
        } else {
          unsetAllData();
        }
      }
    } else if (testDataId && session && (isReportView || isTestsView)) {
      if (!testData.id || testData.id !== testDataId) {
        fetchTestData(session.id, testDataId);
      }
    }
  }, [testDataId, session, location]);

  const openTest = (entityId, sessionId, packageId) => {
    const url = getTestSessionURL({
      entityId,
      sessionId,
      packageType: packageId,
      locale,
      sporterId
    });
    openNewWindow(url);
  };

  return (
    <Panel active={active.isExact}>
      <PanelHeader>
        <BreadcrumbPath
          crumbs={[
            {
              path: generatePath(ROUTE_SPORTERS_ENTITY, {
                entityId,
                groupId
              }),
              label: intl.formatMessage(messages.breadcrumbSporters)
            },
            {
              path: generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
                entityId,
                groupId,
                sporterId
              }),
              label: intl.formatMessage(messages.breadcrumbSportersSessions, {
                firstname: person.firstname,
                lastname: person.lastname
              })
            },
            {
              path: generatePath(ROUTE_SPORTERS_ENTITY, {
                entityId,
                groupId
              }),
              label: intl.formatMessage(messages.breadcrumbSporters)
            }
          ]}
        />
        <PanelTitleWrapper>
          <PanelTitle>
            {session.name}

            <PanelTitleButtonsGroup>
              {!testData &&
                session.finished !== FinishedState.FINISHED &&
                session.config.type !== PackageTypes.MTS && (
                  <PanelTitleButton
                    primary
                    small
                    icon="test-white"
                    onClick={() =>
                      openTest(
                        rootEntityId,
                        session.id,
                        session?.config?.type ?? 'talent'
                      )
                    }
                  />
                )}
              {ability.can('delete', 'Sessions') && (
                <MenuWrapper trigger={<PanelTitleButton dataQa={'session'} />}>
                  {testData && (
                    <>
                      <DownloadReportsButton
                        session={session}
                        person={person}
                        testData={testData}
                        options={options}
                        type={'individual'}
                      />
                    </>
                  )}
                  <RemoveSessionButton
                    menu
                    entityId={rootEntityId}
                    sessionId={session.id}
                    sporterId={sporterId}
                  >
                    <FormattedMessage
                      {...messages.menuItemSporterRemoveSession}
                    />
                  </RemoveSessionButton>
                  <RemoveSporterFromSessionButton
                    menu
                    rootEntityId={rootEntityId}
                    entityId={entityId}
                    sessionId={session.id}
                    sporterId={sporterId}
                    ids={[sporterId]}
                    onComplete={() =>
                      push(
                        generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
                          entityId,
                          groupId,
                          sporterId
                        })
                      )
                    }
                  >
                    <FormattedMessage
                      {...messages.menuItemSporterRemoveSporterFromSession}
                    />
                  </RemoveSporterFromSessionButton>
                </MenuWrapper>
              )}
            </PanelTitleButtonsGroup>
          </PanelTitle>
          <PanelSubtitle>
            {session.startdate && person.birthdate && (
              <FormattedMessage
                {...messages.labelSessionSporterAgeAtTestDate}
                values={{
                  age: getAgeFromDobOnSession(
                    person.birthdate,
                    session.startdate
                  )
                }}
              />
            )}
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        {sessionLoading || testDataLoading ? (
          <Loader />
        ) : (
          <>
            {session?.id && !isSessionRootView && (
              <SessionTabsView
                session={session}
                entityId={entityId}
                edit={edit}
              />
            )}
          </>
        )}
      </PanelBody>
    </Panel>
  );
};

const SessionTabsView = ({ session, ...props }) => {
  if (session.isPrevention || session.isTalentV2 || session.isIDo) {
    return <SessionTabs {...props} />;
  } else {
    return <SessionTalentV1 {...props} />;
  }
};

export default PersonSession;
