import React, { useEffect, useState } from 'react';
import Modal from 'components/modal/Modal';
import {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle
} from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import classNames from 'classnames';
import { Player, BigPlayButton } from 'video-react';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import { FormattedMessage } from 'react-intl';
import DOMPurify from 'dompurify';
import HLSSource from 'components/video/HLSSource';

function TestItemPreviewModal({
  data: { title, category, video_link, mux_link, protocol },
  onClose
}) {
  const [video, setVideo] = useState(null);
  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  useEffect(() => {
    if (mux_link) {
      setVideo(mux_link);
    } else if (video_link) {
      setVideo(`${video_link}.mp4`);
    }
  }, [video, video_link]);

  return (
    <Modal
      card
      isOpen={true}
      extraClassNames={'c-card-modal__content-body-scroll'}
      onClose={onCloseHandler}
    >
      <CardHeader modal>
        <CardHeaderTitle>{title}</CardHeaderTitle>
        {category && <CardHeaderSubtitle>{category}</CardHeaderSubtitle>}
      </CardHeader>
      <CardBody modal>
        {video && (
          <div className={'c-file-upload__video'}>
            <label className={classNames('c-input__label', {})}>
              <FormattedMessage {...messages.testItemLabelVideo} />
            </label>

            <Player loop BigPlayButton={false}>
              {mux_link ? (
                <HLSSource isVideoChild src={mux_link} />
              ) : (
                <source src={video} />
              )}
              <BigPlayButton position="center" />
            </Player>
          </div>
        )}

        {protocol && (
          <div className={'c-textarea--editor'}>
            <div
              className={'field-item'}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  protocol.replaceAll('<p>&nbsp;</p>', '')
                )
              }}
            />
          </div>
        )}
      </CardBody>
      <CardFooter modal>
        <ButtonsGroup>
          <Button secondary onClick={onCloseHandler}>
            <FormattedMessage {...messages.cardButtonClose} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
}

export default TestItemPreviewModal;
