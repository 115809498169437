import classNames from 'classnames';
import './Table.scss';

const Table = props => {
  const {
    children,
    fixed,
    editable,
    tableReport,
    errorList,
    extraClassNames,
    isEdit,
    benchmarkTable,
    growthPredictionTable,
    verticalTop,
    valueTable,
    compressedTable
  } = props;

  return (
    <table
      className={classNames('o-table c-table', extraClassNames, {
        'o-table--fixed ': fixed,
        'c-table--error-list': errorList,
        'c-table--editable': editable,
        'c-table--is-editing': isEdit,
        'c-table--report-tab': tableReport,
        'c-table--vertical-top': verticalTop,
        'c-table--benchmark': benchmarkTable,
        'c-table--benchmark c-table--values': valueTable,
        'c-table--editable c-table--growth': growthPredictionTable,
        'c-table--compressed': compressedTable
      })}
    >
      {children}
    </table>
  );
};

export default Table;
