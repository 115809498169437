import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { observer } from 'mobx-react';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ROUTE_EXERCISES_TRAININGS_ENTITY,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS_EDIT,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES
} from 'routes/RouteList';
import messages from 'messages';
import PanelBody from 'components/panel/PanelBody';
import { TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import useTabIndex from 'hooks/utils/useTabIndex';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { useNotificationQueue } from 'components/notification';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { PanelSubtitle, PanelTitleButton } from 'components/panel/panel-title';
import { MenuWrapper } from 'components/menu';
import RemoveExerciseTemplateButton from 'containers/partials/buttons/RemoveExerciseTemplateButton';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import RemoveExercisesFromTemplateButton from 'containers/partials/buttons/RemoveExercisesFromTemplateButton';
import { TemplateType } from 'constants.js';
import DownloadExerciseProgramTemplateButton from 'containers/partials/buttons/DownloadExerciseProgramTemplateButton';
import ShowEmbedUrlModal from 'containers/pages/exercises/trainings/ShowEmbedUrlModal';
import { useStore } from 'stores/Store';
import TrainingDetailsTab from 'containers/pages/exercises/trainings/TrainingDetailsTab';
import TabLink from 'components/tabs/TabLink';
import TrainingExercisesTab from 'containers/pages/exercises/trainings/TrainingExercisesTab';
import AddExercisesModal from 'containers/pages/exercises/exercises/AddExercisesModal';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import useTrainings from 'hooks/queries/useTrainings';
import useTraining from 'hooks/queries/useTraining';
import TrainingForm from 'containers/pages/exercises/trainings/TrainingForm';

const Training = ({ crumbs }) => {
  const { push } = useHistory();
  const { entityId, templateId, exerciseId } = useParams();
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const setSelectedExercises = useStore(state => state.setSelectedExercises);
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
    [
      ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS_EDIT,
      ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS
    ]
  ]);
  const active = useRouteMatch([
    ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
    ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS_EDIT,
    ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS
  ]);
  const [addExercisesModal, setAddExercisesModal] = useState(null);
  const { fetchTraining, loading, error } = useTraining();
  const { fetchTrainings } = useTrainings(entityId);
  const training = useStore(state => state.training);

  const isExercisesTab = useRouteMatch({
    path: ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
    strict: true,
    exact: true
  });
  const isDetailsTab = useRouteMatch({
    path: [
      ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS,
      ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS_EDIT
    ],
    strict: true,
    exact: true
  });

  const selectedTrainingExercises = useStore(
    state => state.selectedTemplateExercises
  );
  const setSelectedTrainingExercises = useStore(
    state => state.setSelectedTemplateExercises
  );
  const templateSortableActive = useStore(
    state => state.templateSortableActive
  );
  const setTemplateSortableActive = useStore(
    state => state.setTemplateSortableActive
  );

  const [showEmbedUrl, setShowEmbedUrl] = useState(false);

  useEffect(() => {
    if (templateId) {
      fetchTraining({ variables: { templateId } });
    }
    return () => {
      setTemplateSortableActive(false);
    };
  }, [templateId]);

  useEffect(() => {
    if (training) {
      setSelectedExercises(training.exercises);
    }
  }, [training]);

  useLayoutEffect(() => {
    // Redirect to the exercises tab if the user is on the training root url
    if (!isExercisesTab && !isDetailsTab && !exerciseId) {
      push(
        generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES, {
          entityId,
          templateId
        })
      );
    }
  }, []);

  const onAddedHandler = () => {
    setAddExercisesModal(false);
  };

  const onTrainingDeleted = async templateId => {
    notification.add(`removed_${templateId}`, {
      message: intl.formatMessage(messages.messageRemovedtrainingsSuccess)
    });
    await fetchTrainings({
      variables: { entityId, type: TemplateType.TRAINING },
      fetchPolicy: 'network-only'
    });
    push(
      generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY, {
        entityId
      })
    );
  };

  if (!ability.can('read', training)) {
    push(
      generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY, {
        entityId,
        templateId
      })
    );
  }

  if (loading || !training)
    return (
      <Panel active={active?.isExact}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <Panel active={active?.isExact}>
      <PanelHeader>
        {crumbs && <BreadcrumbPath crumbs={crumbs} />}
        <PanelTitleWrapper>
          <PanelTitle>
            {`${training.title}`}
            <MenuWrapper trigger={<PanelTitleButton />}>
              {ability.can('update', training) && (
                <Button menu onClick={() => setAddExercisesModal(training)}>
                  <Icon id="add" />
                  <FormattedMessage {...messages.exercisesAddExercisesButton} />
                </Button>
              )}

              <DownloadExerciseProgramTemplateButton
                templateId={training.id}
                collectionType={TemplateType.TRAINING}
              />
              {(ability.can('embed', 'Trainings') ||
                ability.can('share', 'Trainings')) && (
                <Button menu onClick={() => setShowEmbedUrl(true)}>
                  <Icon id="download" />
                  <FormattedMessage {...messages.openCopyEmbedModal} />
                </Button>
              )}

              {ability.can('update', training) && (
                <RemoveExercisesFromTemplateButton
                  disabledMessage={
                    templateSortableActive
                      ? intl.formatMessage(
                          messages.collectionRemoveExerciseDisabledDragging
                        )
                      : intl.formatMessage(
                          messages.collectionRemoveExerciseDisabledNotSelected
                        )
                  }
                  entityId={entityId}
                  type="training"
                  exerciseIds={selectedTrainingExercises.map(e => e.id)}
                  templateId={templateId}
                  updated={async () => {
                    setSelectedTrainingExercises([]);
                    await fetchTraining({
                      variables: { templateId },
                      fetchPolicy: 'network-only'
                    });
                    await fetchTrainings({
                      variables: { entityId, type: TemplateType.TRAINING }
                    });
                  }}
                  disabled={selectedTrainingExercises.length === 0}
                />
              )}
              {ability.can('update', training) &&
                training.exercises.length > 0 && (
                  <Button
                    menu
                    onClick={() => setTemplateSortableActive(true)}
                    disabled={
                      selectedTrainingExercises.length > 0 ||
                      templateSortableActive
                    }
                  >
                    {templateSortableActive ? (
                      <FormattedMessage
                        {...messages.collectionSortExercisesDisabledDragging}
                      />
                    ) : (
                      <FormattedMessage {...messages.sortCollectionButton} />
                    )}
                    <Icon id="arrange" strokeColor="color-neutral-dark" />
                  </Button>
                )}
              {ability.can('delete', training) && (
                <RemoveExerciseTemplateButton
                  entityId={entityId}
                  templateId={templateId}
                  collectionType={TemplateType.TRAINING}
                  menu
                  onDeleted={templateId => onTrainingDeleted(templateId)}
                >
                  <FormattedMessage {...messages.removeTrainingButton} />
                </RemoveExerciseTemplateButton>
              )}
            </MenuWrapper>
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage
              {...messages.exercisesLengthInIt}
              values={{ this: training.exercises.length }}
            />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <TabLink
              to={generatePath(
                ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
                {
                  entityId,
                  templateId
                }
              )}
            >
              <FormattedMessage {...messages.trainingsExercises} />
            </TabLink>
            {ability.can('update', training) && (
              <TabLink
                to={generatePath(
                  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS,
                  {
                    entityId,
                    templateId
                  }
                )}
              >
                <FormattedMessage {...messages.trainingsDetails} />
              </TabLink>
            )}
          </TabList>
          <TabPanel>
            <TabPanelBody
              withHeader
              style={{
                overflow: 'visible',
                padding: 0,
                margin: '0 -16px',
                maxHeight: '100%'
              }}
            >
              <TrainingExercisesTab
                onAddExercises={training => setAddExercisesModal(training)}
              />
            </TabPanelBody>
          </TabPanel>
          {ability.can('update', training) && (
            <TabPanel>
              <TabPanelBody withScroller>
                <TrainingDetailsTab training={training} />
              </TabPanelBody>
            </TabPanel>
          )}
        </Tabs>
      </PanelBody>
      {ability.can('update', training) && addExercisesModal && (
        <TrainingForm
          entityId={entityId}
          training={training}
          type={TemplateType.TRAINING}
          onComplete={() => setAddExercisesModal(false)}
        >
          {() => (
            <AddExercisesModal
              entityId={entityId}
              template={training}
              onClose={() => {
                setAddExercisesModal(false);
              }}
              onAdded={() => onAddedHandler()}
            />
          )}
        </TrainingForm>
      )}
      {showEmbedUrl &&
        (ability.can('embed', 'Trainings') ||
          ability.can('share', 'Trainings')) && (
          <ShowEmbedUrlModal
            isOpen={showEmbedUrl}
            templateId={templateId}
            onClose={() => setShowEmbedUrl(false)}
          />
        )}
    </Panel>
  );
};

export default observer(Training);
