import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { observer } from 'mobx-react';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import {
  PanelBody,
  PanelSubtitle,
  PanelTitleButtonsGroup
} from 'components/panel';
import { PanelTitle, PanelTitleButton } from 'components/panel/panel-title';
import {
  ROUTE_EXERCISES_TRAININGS_ENTITY,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES
} from 'routes/RouteList';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { MenuWrapper } from 'components/menu';
import { StoreContext } from 'index';
import Loader from 'components/loader/Loader';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import TemplatesListItem from 'containers/partials/list-items/TemplatesListItem';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import useSelectable from 'hooks/utils/useSelectable';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { TabList, Tab, TabPanel, Tabs, TabPanelBody } from 'components/tabs';
import AddTrainingModal from 'containers/pages/exercises/trainings/AddTrainingModal';
import useTabIndex from 'hooks/utils/useTabIndex';
import { Link } from 'react-router-dom';
import FieldInput from 'components/input/FieldInput';
import Training from 'models/Training';
import useTrainings from 'hooks/queries/useTrainings';
import { useStore } from 'stores/Store';
import { TemplateType } from 'constants.js';
import RefactoredPanelHeader from 'components/panel/RefactoredPanelHeader';
import RefactoredPanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import AddButton from 'containers/partials/buttons/AddButton';

const Trainings = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { entityId, templateId } = useParams();
  const ability = useAbility(AbilityContext);
  const trainings = useStore(state => state.trainings);
  const { fetchTrainings, filterTrainings, loading } = useTrainings(
    entityId,
    user
  );

  const [addModalData, setAddModalData] = useState(null);

  const { selected, select } = useSelectable([]);
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_EXERCISES_TRAININGS_ENTITY
  ]);
  const active = useRouteMatch([ROUTE_EXERCISES_TRAININGS_ENTITY]);

  useEffect(() => {
    const getTrainings = async () => {
      await fetchTrainings(
        { variables: { entityId, type: TemplateType.TRAINING } },
        user
      );
    };

    getTrainings();
  }, []);

  const onSearch = async e => {
    filterTrainings({ search: e.target.value });
  };

  const onClickHandler = training => {
    push(
      generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES, {
        entityId,
        templateId: training.id
      })
    );
  };

  const onEditHandler = training => {
    push(
      generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING, {
        entityId,
        templateId: training.id
      })
    );
  };

  const onDeleteHandler = templateId => {
    push(
      generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY, {
        entityId,
        templateId
      })
    );
  };

  return (
    <Panel active={active.isExact}>
      <RefactoredPanelHeader>
        <RefactoredPanelTitleWrapper>
          <BreadcrumbPath crumbs={[]} />
          <PanelTitle>
            <FormattedMessage {...messages.trainingsTitle} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.trainingsSubTitle} />
          </PanelSubtitle>
        </RefactoredPanelTitleWrapper>
        <PanelTitleButtonsGroup>
          {ability.can('create', 'Training') && (
            <AddButton
              expandable
              label={
                <FormattedMessage {...messages.exercisesAddTrainingButton} />
              }
              onClick={() => setAddModalData(true)}
            ></AddButton>
          )}
          <MenuWrapper trigger={<PanelTitleButton />} />
        </PanelTitleButtonsGroup>
      </RefactoredPanelHeader>
      <PanelBody>
        {loading && <Loader />}
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <Tab key={`trainings.my`}>
              <Link
                to={generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY, {
                  entityId
                })}
              >
                <FormattedMessage {...messages.trainingsTabTrainings} />
              </Link>
            </Tab>
          </TabList>
          <TabPanel>
            <TabPanelBody withScroller withHeader>
              <FieldInput
                rounded
                icon="search-grey"
                iconLeft
                onChange={onSearch}
              />
              {trainings && trainings.length ? (
                <List>
                  {/* <ListHeader noTitleStatus>
                <ListColumnTitle
                // ascendent // in comment totdat het werkt:
                // TODO: rangschikking list by column title
                >
                  <FormattedMessage
                    {...messages.trainingsHeaderListTrainings}
                  />
                </ListColumnTitle>
                <ListColumnTitle>
                  <FormattedMessage
                    {...messages.trainingsHeaderListExercisesQty}
                  />
                </ListColumnTitle>
              </ListHeader> */}
                  <ListBody>
                    {trainings.map(training => (
                      <TemplatesListItem
                        checkbox={false}
                        icon="program"
                        mediumIcon
                        visible={selected.length > 0}
                        exercisesLength={training.exercises.length}
                        id={training.id}
                        key={training.id}
                        template={training}
                        entityId={entityId}
                        active={training.id === templateId}
                        checked={selected.includes(training.id)}
                        onChange={() => select(training.id)}
                        onClick={() => {
                          return onClickHandler(training);
                        }}
                        onEdit={() => onEditHandler(training)}
                        onDeleted={() => onDeleteHandler(templateId)}
                      />
                    ))}
                  </ListBody>
                </List>
              ) : (
                <EmptyTrainingList />
              )}
            </TabPanelBody>
          </TabPanel>
        </Tabs>
      </PanelBody>
      {addModalData && (
        <AddTrainingModal
          entityId={entityId}
          training={new Training({})}
          onClose={() => setAddModalData(false)}
        />
      )}
    </Panel>
  );
};

export default observer(Trainings);

const EmptyTrainingList = () => (
  <Card centered>
    <CardBody empty>
      <Message emptyState={true} icon="browse">
        <MessageText>
          <FormattedMessage {...messages.templateListEmpty} />
        </MessageText>
      </Message>
    </CardBody>
  </Card>
);
