import { useContext, useEffect, useState } from 'react';
import { generatePath, useParams, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE
} from 'routes/RouteList';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Panel from 'components/panel/Panel';
import { PanelBody, PanelHeader, PanelTitleButton } from 'components/panel';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import Loader from 'components/loader/Loader';
import { TabPanelBody, Tabs } from 'components/tabs';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { StoreContext } from 'index';
import MenuWrapper from 'components/menu/MenuWrapper';
import useExercise from 'hooks/queries/useExercise';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import RemoveExerciseButton from 'containers/partials/buttons/RemoveExerciseButton';
import Card from 'components/card/Card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import CardBody from 'components/card/CardBody';
import MediaViewer from 'containers/partials/media/MediaViewer';
import { sortByArray } from 'utils/sort';
import Cues from 'containers/partials/inputs/Cues';
import DOMPurify from 'dompurify';
import { useStore } from 'stores/Store';

const getNote = (exercise, notes) => {
  if (notes && notes.length > 0) {
    return notes[0].note;
  }
  if (exercise?.note) {
    return exercise.note;
  }
  return '';
};

const PersonProgramExercise = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const active = useRouteMatch([
    ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE
  ]);
  const [exercise, setExercise] = useState(null);
  const { entityId, groupId, sporterId, programId, exerciseId } = useParams();
  const program = useStore(state => state.program);
  const { loading, error, fetchExercise } = useExercise(entityId, exerciseId);

  useEffect(() => {
    const getExercise = async () => {
      const exercise = await fetchExercise(exerciseId);
      setExercise(exercise);
    };
    if (exerciseId) {
      getExercise();
    }
  }, [exerciseId]);

  if (loading || !exercise)
    return (
      <Panel active={!!active}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <Panel active={!!active}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={generatePath(ROUTE_SPORTERS_ENTITY, {
              entityId,
              groupId
            })}
          >
            <FormattedMessage {...messages.breadcrumbSporters} />
          </Breadcrumb>
          <Breadcrumb
            to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS, {
              entityId,
              groupId,
              sporterId
            })}
          >
            <FormattedMessage {...messages.breadcrumbPrograms} />
          </Breadcrumb>
          <Breadcrumb
            to={generatePath(
              ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
              {
                entityId,
                groupId,
                sporterId,
                programId
              }
            )}
          >
            <FormattedMessage {...messages.breadcrumbProgram} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <span className="" title={exercise.title}>
              {exercise.title}
            </span>

            {user.canRemoveExercises(entityId) && exercise.editable && (
              <MenuWrapper trigger={<PanelTitleButton />}>
                <RemoveExerciseButton
                  entityId={entityId}
                  exerciseId={exerciseId}
                />
              </MenuWrapper>
            )}
          </PanelTitle>

          <PanelSubtitle>
            <FormattedMessage
              {...messages.exercisesLabelLevelSubtitle}
              values={{ level: exercise.level }}
            />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody withFixedButtonsBottom>
        <Tabs defaultIndex={0}>
          <TabList>
            <Tab>
              <FormattedMessage {...messages.breadcrumbExercise} />
            </Tab>
          </TabList>
          <TabPanel>
            <TabPanelBody withScroller>
              <Card secondary multiBody>
                <CardHeader secondary />
                <CardBody secondary separatorBottom>
                  {/* NOTES */}
                  <div
                    className={
                      'c-input__group c-input--closed c-input--read-only'
                    }
                  >
                    <span className={'c-input__label'}>
                      <FormattedMessage {...messages.exercisesLabelNotes} />
                    </span>
                    <div className="c-input--textarea-readonly">
                      {getNote(exercise, program?.notes[exercise.id])}
                    </div>
                  </div>

                  {/* MEDIA */}
                  <MediaViewer
                    entityId={entityId}
                    id={exercise.id}
                    media={
                      exercise.media.length > 0
                        ? exercise.meta?.order
                          ? sortByArray(exercise.media, exercise.meta.order)
                          : exercise.media
                        : []
                    }
                  />

                  {/* CUES */}
                  <Cues
                    entityId={entityId}
                    label={`${intl.formatMessage(messages.exercisesLabelCues)}`}
                    cues={exercise.cues}
                    readOnly={true}
                  />
                </CardBody>

                {/* PROTOCOL */}
                <CardHeader secondary>
                  <CardHeaderText>
                    <CardHeaderTitle>
                      <FormattedMessage {...messages.exercisesLabelProtocol} />
                    </CardHeaderTitle>
                  </CardHeaderText>
                </CardHeader>
                <CardBody secondary>
                  {exercise.protocol && (
                    <div className={'c-textarea--editor'}>
                      <div
                        className={'field-item'}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(exercise.protocol)
                        }}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </TabPanelBody>
          </TabPanel>
        </Tabs>
      </PanelBody>
    </Panel>
  );
};

export default PersonProgramExercise;
