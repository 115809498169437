import React from 'react';
import { TableCell } from 'components/table';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import { getGrowthColor } from 'containers/pages/benchmarks/utils/benchmarkUtil';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import TooltipPopper from 'components/tooltip/TooltipPopper';
import slugify from 'slugify';
import Graphic from 'components/graphics/Graphic';
import Tooltip from 'components/tooltip/Tooltip';

const GrowthTableCell = ({ cell, style, index }) => {
  const row = cell.row;
  const col = cell.column;
  const data = cell.getValue();

  if (typeof data === 'string') {
    return (
      <TableCell
        mainCell
        sortedBy={index >= 1 && col.getIsSorted()}
        style={style}
      >
        <div className="o-flex">
          {data}
          {row.original?.alertOldData && (
            <Tooltip
              id="warning-date-2"
              iconFillColor
              alert
              right
              smallIcon
              icon="alert-round"
              style={{
                display: 'inline-flex',
                zIndex: 1,
                marginLeft: 'auto',
                textTransform: 'none'
              }}
            >
              <FormattedMessage {...messages.growthPredictionTableWarning} />
            </Tooltip>
          )}
        </div>
      </TableCell>
    );
  }

  return (
    <TableCell
      sortedBy={col.getIsSorted()}
      outfaded={row.original?.alertOldData}
      data-value={col.id}
      style={style}
    >
      <ResultCell
        testItemName={col.id}
        data={data}
        userName={row.original.person}
      />
    </TableCell>
  );
};

export default GrowthTableCell;

// TODO: work in progress
// still need to adapt the cases for inputs with colors, and icons
const ResultCell = ({ testItemName, data, userName }) => {
  const { value, unit, alertLevel, iconWarning } = data ? data : {};

  if (testItemName === 'developmentZScore') {
    return value && Array.isArray(value) && value.some(e => e !== false) ? (
      <div
        className={classNames('r-table-cell__content')}
        style={{
          maxWidth: '75%',
          margin: 'auto',
          justifyContent: 'space-between'
        }}
      >
        {value.map((val, i) => {
          return (
            <span
              key={i}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(val, {
                  ALLOWED_TAGS: ['span']
                })
              }}
            />
          );
        })}
      </div>
    ) : (
      <div className="r-table-cell__content">&mdash;</div>
    );
  } else if (testItemName === 'pah90Ci') {
    return (
      <div className="r-table-cell__content">
        {value && typeof value === 'string' ? (
          <>
            {value}
            {unit && <span className="r-table-cell__unit">{unit}</span>}
          </>
        ) : (
          <>&mdash;</>
        )}
      </div>
    );
  } else if (testItemName === 'growthPhase') {
    return value && typeof value === 'string' ? (
      <div
        className={`r-table-cell__content r-table-cell__content-colors u-${alertLevel >= 0 && getGrowthColor(alertLevel)}`}
      >
        <span className="u-text-uppercase">{value}</span>
        {alertLevel >= 0 && (
          <Icon
            id={iconWarning ? 'warning-sign' : 'check-circle'}
            extraClassNames={'c-icon--on-table-cell'}
          />
        )}
      </div>
    ) : (
      <div className="r-table-cell__content">&mdash;</div>
    );
  } else if (testItemName === 'preciseAge') {
    return (
      <div className="r-table-cell__content">
        {value && typeof value === 'number' ? (
          <FormattedMessage
            {...messages.growthTrackerValueUnit}
            values={{
              value,
              unit,
              span: chunks => (
                <span className="r-table-cell__unit">{chunks}</span>
              )
            }}
          />
        ) : (
          <>&mdash;</>
        )}
      </div>
    );
  } else if (testItemName === 'predictedAdultHeight') {
    // const value = 81.21;
    return (
      <div
        className={classNames(
          'r-table-cell__content',
          `u-${getGrowthColor(alertLevel)}`
        )}
      >
        {value ? (
          <TooltipPopper
            inheritedStyles
            id={`table-cell-${testItemName}-user-${slugify(userName)}`}
            labelObject={
              <>
                <span>
                  <FormattedMessage
                    {...messages.growthTrackerValueUnit}
                    values={{
                      value,
                      unit,
                      span: chunks => (
                        <span className="r-table-cell__unit">{chunks}</span>
                      )
                    }}
                  />
                </span>
                {alertLevel >= 0 && (
                  <Icon
                    id={iconWarning ? 'warning-sign' : 'check-circle'}
                    extraClassNames={'c-icon--on-table-cell'}
                    inheritColor={alertLevel >= 0 && getGrowthColor(alertLevel)}
                  />
                )}
              </>
            }
            openOnClick
            onClick={() => true}
            graphic
          >
            <p className="u-margin-bottom-small">
              <FormattedMessage
                {...messages[`growthTrackerPahLegendLvl${alertLevel}`]}
                values={{
                  person: userName,
                  b: chunks => <b>{chunks}</b>
                }}
              />
            </p>
            {value && (
              <Graphic
                pah
                pahRefactored
                value={parseFloat(value.toFixed(2))}
                position={value > 99.4 ? 99.4 : value < 80.1 ? 80.1 : value}
                extraClassNames={[
                  value > 99
                    ? 'c-graphic--value-right'
                    : value < 81.19
                      ? 'c-graphic--value-left'
                      : null,
                  `u-${getGrowthColor(alertLevel)}`
                ]}
              />
            )}
            {/* <p className="u-margin-top-small u-margin-bottom-none">
              Etiam imperdiet diam at porta cursus. Nulla rhoncus sit amet nisi
              in semper. Curabitur venenatis hendrerit nisl, in pulvinar massa
              blandit at.
            </p> */}
          </TooltipPopper>
        ) : (
          <>&mdash;</>
        )}
      </div>
    );
  } else {
    return (
      <div className="r-table-cell__content">
        {value && typeof value === 'number' ? (
          <>
            <FormattedMessage
              {...messages.growthTrackerValueUnit}
              values={{
                value,
                unit,
                span: chunks => (
                  <span className="r-table-cell__unit">{chunks}</span>
                )
              }}
            />
            {alertLevel >= 0 && (
              <Icon
                id={iconWarning ? 'warning-sign' : 'check-circle'}
                extraClassNames={'c-icon--on-table-cell'}
                inheritColor={alertLevel >= 0 && getGrowthColor(alertLevel)}
              />
            )}
          </>
        ) : (
          <>&mdash;</>
        )}
      </div>
    );
  }
};
