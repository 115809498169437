import React, { useContext, useEffect, useState } from 'react';
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelSubtitle,
  PanelTitle,
  PanelTitleButton,
  PanelTitleWrapper
} from 'components/panel';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import { StoreContext } from 'index';
import { useMedicalContext } from 'contexts/MedicalContext';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL_EDIT
} from 'routes/RouteList';
import { MenuWrapper } from 'components/menu';
import { FormattedMessage } from 'react-intl';
import RemoveScreeningButton from 'containers/partials/buttons/RemoveScreeningsButton';
import messages from 'messages';
import { getLocalDateFormat } from 'utils/date';
import Loader from 'components/loader/Loader';
import { TestsProvider } from 'contexts/TestsContext';
import { useSessionContext } from 'contexts/SessionContext';
import DownloadEmptyFormButton from 'containers/partials/buttons/DownloadEmptyFormButton';
import { useTestDataContext } from 'contexts/TestDataContext';
import CopySessionButton from 'containers/partials/buttons/CopySessionButton';
import AddScreeningModal from 'containers/pages/rehab/screening/AddScreeningModal';
import ScreeningTabs from 'containers/pages/rehab/screening/ScreeningTabs';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import usePerson from 'hooks/queries/usePerson';
import DownloadReportsButton from 'containers/partials/session-report-buttons/DownloadReportsButton';

export const parseTestSetTests = tests => {
  if (!tests) return [];

  return tests.map(test => {
    // find if it's a dependency
    const isDependency = tests.some(ts => {
      try {
        return ts.dependedTestIds?.includes(test.id);
      } catch (e) {
        return [];
      }
    });

    // find it's status
    const status = true;

    // add sides
    let sides;
    switch (test.laterality.includes('bilateral_')) {
      case 1:
        sides = [];
        break;
      case 2:
        sides = ['left', 'right'];
        break;
      default:
        break;
    }

    return { ...test, isDependency, status, sides };
  });
};

function Screening() {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const {
    entityId,
    groupId,
    sporterId,
    medicalId,
    injuryId,
    sessionId,
    testDataId
  } = useParams();
  const { push } = useHistory();
  let location = useLocation();

  const { fetchPerson } = usePerson({ personId: sporterId });
  const person = useEntityContext(s => s.person);
  const { medical, loading: medicalLoading } = useMedicalContext();
  const { session: screening, sessionLoading, options } = useSessionContext();
  const { testData, fetchTestData, unsetAllData, testDataLoading } =
    useTestDataContext();
  const [personInjury, setPersonInjury] = useState({});
  const [copySessionModal, setCopySessionModal] = useState(false);
  const active = useRouteMatch([
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA_EDIT,
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA,
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS,
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA,
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT,
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL_EDIT,
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL,
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY
  ]);

  const isScreeningRootView = useRouteMatch({
    path: ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
    strict: true,
    exact: true
  });
  const isReportView = useRouteMatch({
    path: [
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA
    ],
    strict: true
  });
  const isTestsView = useRouteMatch({
    path: [
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA
    ],
    strict: true
  });

  useEffect(() => {
    const fetchPersonData = async () => {
      await fetchPerson(sporterId);
    };
    if (!person.id) {
      fetchPersonData();
    }
  }, [entityId, sporterId]);

  useEffect(() => {
    if (screening?.id !== sessionId) return;

    if (
      (!testDataId && screening && (isReportView || isTestsView)) ||
      isScreeningRootView
    ) {
      const testData = screening.getLastTestData(sporterId);
      if (testData?.id) {
        const selectedPath =
          testData.finishedPercentage > 0 && !isTestsView
            ? ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA
            : ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA;

        push(
          generatePath(selectedPath, {
            entityId,
            groupId,
            sporterId,
            medicalId,
            injuryId,
            sessionId,
            testDataId: testData.id
          })
        );
      } else {
        if (isScreeningRootView) {
          push(
            generatePath(
              ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS,
              {
                entityId,
                groupId,
                sporterId,
                medicalId,
                injuryId,
                sessionId
              }
            )
          );
        } else {
          unsetAllData();
        }
      }
    } else if (testDataId && screening && (isReportView || isTestsView)) {
      if (!testData?.id || testData.id !== testDataId) {
        fetchTestData(screening.id, testDataId);
      }
    }
  }, [screening, testDataId, location]);

  useEffect(() => {
    if (medical?.injuries) {
      setPersonInjury(medical.injuries.find(i => i.id === injuryId));
    }
  }, [medical, injuryId]);

  if (sessionLoading || medicalLoading || !medical?.id) {
    return (
      <Panel active={!!active}>
        <Loader />
      </Panel>
    );
  }

  /*const showConfirmModal = (callBack, type) => {
    const okHandler = async () => {
      await callBack.method(callBack.query, options);
    };
    if (type === 'mailConfirm') {
      uiState.showModal({
        title: intl.formatMessage(messages.modalConfirmMailReportTitle),
        message: messages.modalConfirmMailReportMessage,
        dismissButton: true,
        type: MODAL_TYPES.WARNING,
        okHandler: okHandler
      });
    }
  };*/

  return (
    <>
      <Panel active={active.isExact}>
        <PanelHeader>
          <Breadcrumbs>
            <Breadcrumb
              to={generatePath(ROUTE_SPORTERS_ENTITY, { entityId, groupId })}
            >
              <FormattedMessage {...messages.breadcrumbSporters} />
            </Breadcrumb>
            <Breadcrumb
              to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
                entityId,
                groupId,
                sporterId
              })}
            >
              {`${person.firstname} ${person.lastname}`}
            </Breadcrumb>
            <Breadcrumb
              to={generatePath(
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
                {
                  entityId,
                  groupId,
                  sporterId,
                  medicalId: medical?.id,
                  injuryId: injuryId
                }
              )}
            >
              <FormattedMessage {...messages.breadcrumbScreening} />
            </Breadcrumb>
          </Breadcrumbs>
          <PanelTitleWrapper>
            <PanelTitle>
              {screening.name}
              {user.canRemoveScreenings(entityId) && (
                <MenuWrapper trigger={<PanelTitleButton />}>
                  <DownloadEmptyFormButton session={screening} />
                  {testData?.id && (
                    <DownloadReportsButton
                      session={screening}
                      testData={testData}
                      person={person}
                      options={options}
                      type={'individual'}
                    />
                  )}
                  <RemoveScreeningButton
                    menu
                    entityId={entityId}
                    personInjuryId={injuryId}
                    testSessionId={sessionId}
                    personId={sporterId}
                  >
                    <FormattedMessage
                      {...messages.rehabRemoveScreeningButton}
                    />
                  </RemoveScreeningButton>
                  <CopySessionButton
                    menu
                    entityId={entityId}
                    session={screening}
                    onCopySession={session => setCopySessionModal(session)}
                  />
                </MenuWrapper>
              )}
            </PanelTitle>
            <PanelSubtitle>
              {getLocalDateFormat(screening.startdate)}
            </PanelSubtitle>
          </PanelTitleWrapper>
        </PanelHeader>
        <PanelBody>
          {sessionLoading || testDataLoading ? (
            <Loader />
          ) : (
            <>{screening?.id && !isScreeningRootView && <ScreeningTabs />}</>
          )}
        </PanelBody>
      </Panel>

      {copySessionModal && (
        <TestsProvider entityId={entityId}>
          <AddScreeningModal
            entityId={entityId}
            screening={copySessionModal}
            personInjury={personInjury}
            onClose={() => setCopySessionModal(false)}
          />
        </TestsProvider>
      )}
    </>
  );
}

export default Screening;
